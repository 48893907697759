import { ButtonContent } from "../../global/boton/ButtonContent";

function HeroSection({ bgImg, bgImg1, bgImg2, bgImg3, title, texts }) {


  return (
    <section className={'svgBoxRectanguloBottonRigth'}>

      <div className="pic-wrapper svgHerosection2  w-full h-screen" >
        <video
          playsInline
          autoPlay
          muted
          loop
          className="w-full h-full object-cover absolute z-10"
        >
          <source src={"https://firebasestorage.googleapis.com/v0/b/videos-a7d6e.appspot.com/o/Montgomery%2C%20AL.mp4?alt=media&token=8520a1d4-c559-4d8f-9311-9d2e253daa3d"} />
        </video>
        <div className="z-20 relative w-4/5 mx-auto h-full flex flex-col justify-end items-center pb-32 lg:pb-10">
          <div className="w-full flex flex-col justify-center items-center">
            <h1 className="text-white text-center text-[29px] mb-6 md:text-[35px] lg:text-[50px]">
              {title}
            </h1>
            <p className="text-white text-center text-[14px] lg:text-[16px] leading-8">
              {texts}
            </p>
          </div>

          <div className="w-full flex justify-center py-5">
            <ButtonContent btnStyle={'three'} />
          </div>

        </div>
      </div>


    </section>

  )
}

export default HeroSection;
